import React from 'react';
import PropTypes from "prop-types";

import './index.scss';

const Divider = ({ 
    orientation,
    dividerColor,
    customStyle
 }) => (
    <div className={`divider divider-${orientation}`} style={customStyle}>
        <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
        >
            <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill" style={{fill: dividerColor}}></path>
        </svg>
    </div>
);

Divider.propTypes = {
    orientation: PropTypes.string,
    backgroundColor: PropTypes.string,
    dividerColor: PropTypes.string,
    dividerHeight: PropTypes.string,
    customStyle: PropTypes.object,
};

Divider.defaultProps = {
    orientation: "top",
    backgroundColor: "#333",
    dividerColor: "#fff",
    dividerHeight: "150px",
    customStyle: {}
};

export default Divider;