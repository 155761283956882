const step_1 = {
    title: 'Préparation du projet',
    before: {
        title: 'Avant Projet',
        texts: [
            'Définir et comprendre parfaitement ce que vous voulez mettr en oeuvre est une étape cruciale à laquelle nous portons beaucoup d\'attention.',
        ],
    },
    steps: [
        'Entretien pour comprendre le besoin',
        'Audit de l\'existant et/ou de la concurrence',
    ],
    after: {
        title: 'Définition du projet',
        texts: [
            'Définition du besoin réèl, des développement nécessaires et accompagnement dans la rédaction du cahier des charges.',
        ],
    },
};

const step_2 = {
    title: 'Réalisation du projet',
    before: {
        title: 'Conception',
        texts: [
            'Déploiement opérationnel de votre projet de manière concrète. Site web, application, interface administration : à partir de cette étape nous développons votre projet concrètement.',
        ],
    },
    steps: [
        'Reflexion sur l\'UI et l\'UX',
        'Zoning et Wireframe des différentes pages/vues'
    ],
    after: {
        title: null,
        texts: [
            'Webdesign : Les différentes pages / vues sont designées en respectant la charte graphique fournie / celleque nous avons réalisé à votre demande.',
        ],
    },
};

const step_3 = {
    title: 'Livraison du projet',
    before: {
        title: 'Conception',
        texts: [
            'Déploiement opérationnel de votre projet de manière concrète. Site web, application, interface d\'administration: à partir de cette étape, nos développons votre projet concrètement.',
        ],
    },
    steps: [
        'Réflexion sur l\'UI et l\'UX',
        'Zoning et Wireframe des différentes pages/vues'
    ],
    after: null,
};

export const steps = {
    title: 'Déroulement d\'un projet avec Emeka',
    sub_title: 'Premier contact',
    orientation: 'vertical',
    steps: [step_1, step_2, step_3],
    alternate: true,
    first_side_alternate: 'left',
    basic_structure: true,
};
// sbhbhbshs