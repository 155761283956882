import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { useWindowSize } from "../../functions/window-size";

/*
    Ancres pour la signalisation (.map une par contenu)
    If scrollHeight a atteint l'ancre en descendant, trigger changeDown
    If scrollHeight a atteint l'ancre en remontant, trigger changeUp
*/

const _Base = ({ step, num_step }) => {
    
    const windowSize = useWindowSize();

    if(windowSize.width <= 768) {
        return (
            <>
                <TxtPart step={step} num_step={num_step}/>
                <FigurePart num_step={num_step}/>
            </>
        );
    }
    else if(num_step%2 == 1) {
        return (
            <>
                <TxtPart step={step} num_step={num_step}/>
                <FigurePart num_step={num_step}/>
            </>
        );
    }
    else if((num_step%2 == 0)) {
        return (
            <>
                <FigurePart num_step={num_step}/>
                <TxtPart step={step} num_step={num_step}/>
            </>
        );
    }
    else {
        return <></>;
    }
};

_Base.propTypes = {
    step: PropTypes.object.isRequired,
    num_step: PropTypes.number,
};

_Base.defaultProps = {
    num_step: 1,
};

export default _Base;

const TxtPart = ({ step, num_step }) => (
    <div className="txt-part">
        {step.title && <div id={`title-step-${num_step}`} className="title to-reach">{step.title}</div>}
        {step.before && <div className="before to-reach">
            <div id={`before-title-step-${num_step}`} className="before-title">{step.before.title}</div>
            {step.before.texts && step.before.texts.map((text:string, key:number) => (
                <p key={key} className="text">{text}</p>
            ))}
        </div>}
        {step.steps && <div className="arrow-steps to-reach">
            {step.steps.map((step:string, key:number) => (
                <div className="sub-step" key={key}>
                    <span className="pre-arrow">{'->'}</span>
                    <span className="arrow-step">{step}</span>
                </div>
            ))}
        </div>}
        {step.after && <div className="after to-reach">
            {step.after.title && <div id={`after-title-step-${num_step}`} className="after-title">{step.after.title}</div>}
            {step.after.texts && step.after.texts.map((text:string, key:number) => (
                <p key={key} className="text">{text}</p>
            ))}
        </div>}
    </div>
);

const FigurePart = ({ num_step }) => (
    <div className="figure-part">
        {num_step}
    </div>
);