import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from '../components/seo';

import Banner from "../components/Banner";
import LoaderStepper from "../components/LoaderStepper";
import { steps } from "../components/FakeDatas/home_stepper";
import ExpertiseFields from "../components/ExpertiseFields";
import LastProject from "../components/LastProject";
import LastProjects from "../components/LastProjects";

import { sendRequest } from "../functions/ajax-manage";
import { progressBar } from "../components/LoaderStepper/progressBar";
import { popinManager } from "../functions/caseManagers";

// TODO Ajouter un "main-not-display" pour les résolutions non prises en charges

const BaseTemplate = ({ data }) => {

    // const [datas] = React.useState(data.wpcontent.page);

    const trigger_id = "home-stepper";

    const [ bar ] = React.useState(progressBar);

    React.useEffect(function() {
        bar.init(document.querySelector(`#${trigger_id}.steps`));
        document.addEventListener('scroll', popinManager);
    });

    return (
        <Layout>
            <SEO lang="fr_FR"/>
            <Banner />
            {/* {datas.content} */}
            <LastProject />
            <ExpertiseFields />
            <LastProjects />
            <LoaderStepper trigger_id={trigger_id} {...steps}/>
        </Layout>
    );
};

export default BaseTemplate;

// export const query = graphql`
//     query Page($id: ID!) {
//         wpcontent {
//             page(id: $id) {
//                 content
//                 title
//                 uri
//             }
//         }
//     }
// `;