import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";

import Slider from "../Slider";
import Divider from "../Divider";

import './index.scss';

const LastProjects = ({  }) => {

    const datas = useStaticQuery(graphql`
        {
            allFile(filter: {relativeDirectory: {eq: "home/last_projects"}}, sort: {fields: name, order: ASC}) {
                nodes {
                    name
                    img: childImageSharp {
                        srcs: fluid {
                            src: srcWebp
                            srcSet: srcSetWebp
                        }
                    }
                }
            }
        }
    `);

    return (
        <div id="portfolio" className="last-projects">
            <Divider
                dividerColor="#0e0e0e"
                customStyle={{
                    position: "relative",
                    backgroundColor:"#080808",
                    height:"60%" ,
                    marginBottom: 100,

                }}
                dividerHeight="60%"
                orientation="bottom"
            />
            <h2>Nos dernières réalisations : </h2>
            <Slider datas={datas.allFile.nodes} selector="last-projects" alt_particle="Site"/>
            <Divider
                dividerColor="#0a0a0a"
                customStyle={{
                    marginTop: 100,
                    position: "relative",
                    backgroundColor:"#080808",
                    height:"60%" ,
                }}
                dividerHeight="60%"
            />
        </div>
    );
};

LastProjects.propTypes = {

};

LastProjects.defaultProps = {

};

export default LastProjects;