import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from 'gatsby';

import { projects } from '../FakeDatas/lastproject';

import './index.scss';

const LastProject = () => {
    const img = useStaticQuery(graphql`
    {
        imageProject: file(relativePath: {eq: "home/last_project.png"}, publicURL: {}) {
          childImageSharp {
           fluid{
            srcWebp
            srcSetWebp
           }
          }
        }
        arrow: file(relativePath: {eq: "banner/right-arrow.svg"}) {
            publicURL
            name
        }
      }
    `)
    const { title, subDescription, description } = projects;
    return (
        <div className="lastProject">
            <div className="container">
                <h2>
                    Notre dernier projet : <span>{title}</span>
                </h2>
                <div>
                    <div className="ctn-content">
                        <h3>
                            {subDescription}
                        </h3>
                        <p>
                            {description}
                        </p>
                    </div>
                    <img
                        className="ctn-showProject" 
                        src={img.imageProject.childImageSharp.fluid.srcWebp}
                        srcSet={img.imageProject.childImageSharp.fluid.srcSetWebp}
                        alt="last-image-project"
                    />
                </div>
                <div className="catchText">
                    <span className="tw-medium">Soyez le prochain que nous serons fiers de mettre en avant.</span>
                    <img
                        src={ img.arrow.publicURL }
                    />
                </div>
            </div>
        </div>
    );
};

LastProject.propTypes = {

};

LastProject.defaultProps = {

};

export default LastProject;