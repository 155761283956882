import React from "react";
import PropTypes from "prop-types";

import "./index.scss";
import "./_base.scss";
import { recursiveRender } from "../../functions/recursiveRender";
import _Base from "./_base";
import Divider from "../Divider";

const LoaderStepper = ({
    title,
    sub_title,
    orientation,
    steps,
    alternate,
    first_side_alternate,
    basic_structure,
    trigger_id,
}) => {

    const divider_basic = {
        backgroundColor:"#0a0a0a",
        height:"150px",
        width: "100%",
        // position: 'relative',
        left: 0,
    };

    if(steps == []) {
        return <></>;
    }

    return (
        <div
            className={`loader-stepper${basic_structure ? '-base' : ''} stepper-${orientation} alternate-${alternate ? 'true' : 'false'} first-alternate-${first_side_alternate}`}
        >
            <h2 className="title">{title}</h2>
            <div className="sub-title">{sub_title}</div>
            <div className="steps" id={trigger_id}>
                {/* <div className="stepper-bg"></div> */}
                {steps.map((step:Object, key:number) => (
                    <div className="step" key={key}>
                        {!basic_structure && recursiveRender(step)}
                        {basic_structure && key%2 == 1 && <Divider
                            orientation="top"
                            dividerColor="#080808"
                            customStyle={{...divider_basic, top: 0}}
                        />}
                        <div className="container">
                            {basic_structure && <_Base step={step} num_step={key + 1}/>}
                        </div>
                        {basic_structure && key%2 == 1 && <Divider
                            orientation="bottom"
                            dividerColor="#080808"
                            customStyle={{...divider_basic, bottom: 0}}
                        />}
                    </div>
                ))}
                <div className="center-part">
                    <div className="progress-bar"></div>
                </div>
            </div>
        </div>
    );
};

LoaderStepper.propTypes = {
    title: PropTypes.string,
    sub_title: PropTypes.string,
    orientation: PropTypes.oneOf(['vertical', 'horizontal']),
    steps: PropTypes.array,
    alternate: PropTypes.bool,
    first_side_alternate: PropTypes.oneOf(['left', 'right']),
    basic_structure: PropTypes.bool,
    trigger_id: PropTypes.string,
};

LoaderStepper.defaultProps = {
    title: 'Titre',
    sub_title: 'Sous-titre',
    orientation: 'vertical',
    steps: [],
    alternate: true,
    first_side_alternate: 'left',
    basic_structure: true,
};

export default LoaderStepper;