export const expertiseFields = [
    {
        title: 'Web Design',
        content: 
            `Notre de but est de
            designer des solutions
            qui reprennent le meilleur
            de votre image, racontent 
            votre histoire et améliorent
            votre conversion. Notre 
            équipe design conçoit des
            designs personnalisés 
            centrés sur l'humain, 
            axés sur la conversion`,
    },
    {
        title: 'Marketing Digital',
        content: 
            `Nous créons et mettons en
            place des stratégies de
            marketing numérique 
            multicanales.
            Du référencement à la 
            rédaction de contenu en
            passant par Adwords et 
            Facebook Ads, nous travaillons
            avec vous pour capturer et 
            convertir votre public cible.`,
    },
    {
        title: 'Branding',
        content: 
            `Nous créons et mettons en
            place des stratégies de
            marketing numérique 
            multicanales.
            Du référencement à la 
            rédaction de contenu en
            passant par adwords et 
            facebook ads, nous travaillons
            avec vous pour capturer et 
            convertir votre public cible.`,
    },
    {
        title: 'Developpement',
        content: 
            `Armée des dernières 
            technologies, notre équipe 
            de développement front et
            back-end donne vie à vos 
            designs. Nous développons
            pour maintenant et nous 
            construisons pour le futur.`,
    },
    {
        title: 'Consulting',
        content: 
            `Nous créons et mettons en
            place des stratégies de
            marketing numérique 
            multicanales.
            Du référencement à la 
            rédaction de contenu en
            passant par Adwords et 
            Facebook Ads, nous travaillons
            avec vous pour capturer et 
            convertir votre public cible.`,
    },
]