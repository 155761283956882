import React from "react";
import PropTypes from "prop-types";

import './index.scss';
import { slider } from './slide';
import { graphql, useStaticQuery } from "gatsby";

const Slider = ({ datas, selector, alt_particle }) => {

    const special_case = "04 . Pierre Mathieu - Patissier Bordelais";
    const special_case_solution = "04 . Pierre Mathieu - Pâtissier Bordelais";

    const pics = useStaticQuery(graphql`
        {
            no_pic: file(relativePath: {eq: "no-pic.svg"}, publicURL: {}) {
                src: publicURL
            }
            arrow: file(relativePath: {eq: "thin-arrow.svg"}) {
                publicURL
            }
        }
    `);

    React.useEffect(() => {
        slider.init(document.getElementById(`${selector}-slider`));
    })

    const slideLeft = (e: MouseEvent) => {
        
    }

    const slideRight = (e: MouseEvent) => {
        
    }

    return (
        <div
            className="slider custom-scrollbar scrollbar-no-button"
            id={`${selector}-slider`}
            // onDragStart={(e) => {startDrag(e)}}
            // onDragEnd={(e) => {stopDrag(e)}}
        >
            <div className="arrow left-arrow" onClick={(e) => {slideLeft();}}>
                <img src={pics.arrow.publicURL} alt="left-arrow"/>
            </div>
            {datas.map((elem:any, key:number) => {
                return (
                    <div className="slide" key={key}>
                        {elem.title && <span className="title">{elem.title}</span>}
                        {
                            elem.img
                            &&
                            <img
                                className="main-img"
                                src={elem.img.srcs.src || pics.no_pic.src}
                                srcSet={elem.img.srcs.srcSet || ""}
                                alt={`${alt_particle} ${elem.name}` || `${alt_particle}-slider-img-${key + 1}`}
                            />
                        }
                        {elem.name && <span className="name">{elem.name == special_case ? special_case_solution : elem.name}</span>}
                        {elem.description && <span className="description">{elem.description}</span>}
                    </div>
                );
            })}
            {/* <span className="and-else">et bien d'autres...</span> */}
            <div className="arrow right-arrow" onClick={(e) => {slideRight();}}>
                <img src={pics.arrow.publicURL} alt="right-arrow"/>
            </div>
        </div>
    );
};

Slider.propTypes = {
    datas: PropTypes.any.isRequired,
    selector: PropTypes.string.isRequired
};

Slider.defaultProps = {

};

export default Slider;