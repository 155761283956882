import React from 'react';
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from 'gatsby';

import './index.scss'
import Divider from '../Divider';

import { expertiseFields } from '../FakeDatas/expertiseFields';

const ExpertiseFields = () => {
    return (
        <div className="expertise">
            <Divider
                backgroundColor="#0e0e0e"
                dividerColor="#080808"
                dividerHeight="300px"
                orientation="bottom"
            />
            <div className="container expertise-fields">
                <h2>
                    Domaines d'expertise
                </h2>
                <div className="ctn-fields">
                    {
                        expertiseFields.map((fiedlObj) => (
                            <div className="field-card" key={fiedlObj.title} >
                                <div className="card">
                                    <h3>
                                        {fiedlObj.title}
                                    </h3>
                                    <p>
                                        {fiedlObj.content}
                                    </p>
                                </div>                            
                            </div>
                        ))
                    }                        
                </div>
            </div>
            
        </div>
    );
};

ExpertiseFields.propTypes = {

};

ExpertiseFields.defaultProps = {

};

export default ExpertiseFields;