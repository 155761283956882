export const projects = {
        title: `Inmode`,
        subDescription: `Rebranding, Design & Développement`,
        description: `
            Inmode, leader mondiale dans le secteur de la machinerie de chirurgie esthéthique à disposition des professionnels de l'esthétique a fait appel à nous pour s'occuper du redesign de leur branche française.

            Nous leur avons proposé un design épuré sur base de 3 couleurs principale autours desquel nous avons redessiné leurs pages clés, pour garder une identité forte. 
            
            Nous avons aussi développé pour eux un back-office sur mesure répondant précisément aux besoin de professionnels dans ce milieux. Simple d'utilisation, avec beaucoup de fonctionnalités.
        `,
};
