import React from 'react';
import PropTypes from "prop-types";
import { graphql, Link, StaticQuery } from 'gatsby';

import Divider from '../Divider';

import './index.scss';

const Banner = ({  }) => {

    return (
        <div
            className="banner"
            onMouseMove={(e) => {
                let _w = window.innerWidth/2;
                let _h = window.innerHeight/2;
                let _mouseX = e.clientX;
                let _mouseY = e.clientY;
                document.getElementById('hero-card').style.right = `calc(${(_mouseX - _w) * 0.001}%)`;
                document.getElementById('hero-card').style.top = `${(_h - _mouseY) * 0.008}px`;
            }}
        >
            <div
                id="hero-card"
                className="container card content"
            >
                <h1>
                    Agence Web Le Puy, Emeka
                </h1>
                <h2 className="catch col-lg-8 tw-bold">
                    Vous l'imaginez ? Nous le développerons<span style={{color: '#f00566'}}>.</span>
                </h2>
                <div className="args col-lg-8 tw-medium">
                    <p>
                    Une agence web au service de la PME à la multinationale dans les domaines de la 
                    conception et du développement Web, du marketing et de l'image de marque. 
                    Nous créons des expériences numériques centrées sur l'humain et à l'épreuve du temps.
                    </p>
                </div>
                <div className="work-together">
                    <span className="tw-medium">Travaillons ensemble</span>
                    <StaticQuery
                        query={graphql`
                        {
                            arrow: file(relativePath: {eq: "banner/right-arrow.svg"}) {
                                publicURL
                                name
                            }
                        }
                        `}
                        render={data => 
                            <Link to="/project" title="Project">
                                <img src={data.arrow.publicURL} alt={data.arrow.name}/>
                            </Link>
                        }
                    ></StaticQuery>
                    <Link to="/project" className="absolute-link" title="Project"></Link>
                </div>
            </div>
            <Divider
                dividerColor="#080808"
                customStyle={{
                    backgroundColor:"#0e0e0e",
                    height:"60%" ,
                }}
                dividerHeight="60%"
            />
        </div>
    );
};

Banner.propTypes = {

};

Banner.defaultProps = {

};

export default Banner;